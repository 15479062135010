import React from 'react'
import PT from 'prop-types'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import classes from './Tabs.module.scss'

const Tabs = ({ tabs, className, isMobile = false }) => {
  const tabsClasses = cx(
    'cursor-pointer',
    {
      [classes.tabs]: !isMobile,
      [classes.tabsMobile]: isMobile,
    },
    className,
  )

  const tabClasses = (tab) =>
    cx({
      [classes.tab]: !isMobile,
      [classes.tabMobile]: isMobile,
      [classes.tabActive]: !isMobile && tab.active,
      [classes.tabMobileActive]: isMobile && tab.active,
    })

  const renderTab = (tab) => {
    const Element = tab.onClick ? 'div' : Link

    return (
      <Element
        key={tab.label}
        {...(tab.link && { to: tab.link })}
        {...(tab.onClick && { onClick: tab.onClick })}
        className={tabClasses(tab)}>
        {tab.label}
      </Element>
    )
  }

  return (
    <nav aria-label="Tabs" className={tabsClasses}>
      {tabs.map(renderTab)}
    </nav>
  )
}

Tabs.propTypes = {
  tabs: PT.arrayOf(
    PT.shape({
      active: PT.bool,
      label: PT.string.isRequired,
      link: PT.string,
      onClick: PT.func,
    }),
  ).isRequired,
  isMobile: PT.bool,
  className: PT.string,
}

export default Tabs
