import { snakeCase, toInteger } from 'lodash'
import { DateTime } from 'luxon'
import { getValuesString } from '../components/filters/mobile/filterUtils'

export const getPaginationData = (model) => {
  const paginationData = model?.paginationData
  const totalCount = paginationData?.totalCount || 0
  const totalPages = paginationData?.totalPages || 0
  const from = paginationData?.from || 0
  const to = paginationData?.to || 0
  return { totalCount, totalPages, from, to }
}

export const getModelsByIds = (array, ids) => {
  return array.filter((item) => ids.indexOf(item.id) > -1)
}

export const getSumByField = (array, fieldName) => {
  return array.reduce((sum, item) => sum + +item[fieldName], 0)
}

export const getTableParams = (queryParams) => {
  const sort = queryParams.sort || 'payment_date.desc'
  const page = toInteger(queryParams.page) || 1
  const search = queryParams.search || null
  return { sort, page, search }
}

export const excludeErrorMsg = (error) => {
  if (Array.isArray(error) && error.length) {
    return error.map((error) => error.message).join(', ')
  }
}

export const handleError = (error, notification) => {
  if (Array.isArray(error) && error.length) {
    notification({ error: error[0]?.message })
  }
}

export const parseToFinanceFormat = (value) => {
  if (!value || isNaN(Number.parseFloat(value))) {
    return ''
  }
  return value && Number.parseFloat(value).toFixed(2)
}

export const mapObjectProps = (props, object) => {
  return props.reduce((acc, item) => {
    acc[item] = object[item]
    return acc
  }, {})
}

export const getActiveTab = (location, mainPath) => {
  const pathArray = location.pathname.split('/')
  const indexInvoice = pathArray.indexOf(mainPath)
  return pathArray[indexInvoice + 1]
}

export const getTabsMap = (tabs, activeTab, handleTabClick, t) =>
  tabs.map((tab) =>
    typeof tab === 'object'
      ? {
          label: t(tab.label),
          onClick: () => handleTabClick(tab.route),
          active: activeTab === tab.route,
        }
      : {
          label: t(tab),
          onClick: () => handleTabClick(tab),
          active: activeTab === tab,
        },
  )

export const getNumberWithCommas = (number) => {
  return number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const transformCentsToDollars = (number) => {
  return (number || 0) / 100
}

export const checkOnUniqueElement = (values) => {
  const set = new Set(values)
  return set.size > 1
}

export const parseFilterQuery = (url) => {
  if (url) {
    const filters = url.split('&&')
    return filters
      .map((filter) => {
        const keyIndex = filter.indexOf('.')
        const key = filter.substring(0, keyIndex)
        const stringValues = filter.substring(keyIndex + 1, filter.length)
        const values = stringValues.split('||')
        if (key === 'actionItem') {
          return values.map((value) => ({
            key: value,
            values: ['true'],
          }))
        }
        return {
          key: snakeCase(key),
          values,
        }
      })
      .flat()
  }
  return []
}

export const parseDisplayFilterQuery = (url) => {
  if (url) {
    const filters = url.split('&&')
    return filters.map((filter) => {
      const keyIndex = filter.indexOf('.')
      const key = filter.substring(0, keyIndex)
      const stringValues = filter.substring(keyIndex + 1, filter.length)
      const values = stringValues.split('||')
      return {
        key: snakeCase(key),
        values,
      }
    })
  }
  return []
}

export const filterToString = (filters = {}) => {
  return Object.keys(filters).reduce((string, key) => {
    const filterString = `${key}.${getValuesString(filters[key])}`

    string += string ? `&&${filterString}` : filterString
    return string
  }, '')
}

export const getDateToDatePicker = (date) => {
  return (date && new Date(date)) || ''
}

export const reformatISODate = (date, formatTo) => {
  return (date && DateTime.fromISO(date).toFormat(formatTo)) || ''
}

export const getCreditsTableParams = (queryParams) => {
  const sort = queryParams.sort || 'created_at.desc'
  const page = toInteger(queryParams.page) || 1
  const search = queryParams.search || null
  return { sort, page, search }
}
export const paymentPromiseStatuses = {
  ACTIVE: 'active',
  EXPIRED: 'expired',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
}

export const statusP2PMapping = {
  [paymentPromiseStatuses.ACTIVE]: { label: 'active', color: 'green' },
  [paymentPromiseStatuses.EXPIRED]: { label: 'expired', color: 'red' },
  [paymentPromiseStatuses.COMPLETED]: { label: 'completed', color: 'green' },
  [paymentPromiseStatuses.CANCELLED]: { label: 'cancelled', color: 'white' },
}

export const getOnlyNumbers = (value) => {
  if (typeof value === 'string') {
    return value.replace(/[^\d]/g, '')
  }
}
export const makeOptions = (arr) => {
  if (arr?.every((el) => typeof el === 'string' || typeof el === 'number')) {
    return arr.map((el) => ({
      label: el,
      value: el,
      name: el,
    }))
  } else return null
}
